import request from './../utils/request'
import { goodsDel } from './goods'

// 工厂列表
export function factoryList(data) {
  return request({
    url: '/factory/list',
    method: 'post',
    data: data
  })
}

export function factoryInfo(id) {
  return request({
    url: '/factory/info/' + id,
    method: 'get'
  })
}

// 添加工厂
export function factoryUpsert(data) {
  return request({
    url: '/factory/upsert',
    method: 'post',
    data: data
  })
}

// 删除工厂
export function factoryDel(id) {
  return request({
    url: '/factory/delete/' + id,
    method: 'delete'
  })
}

// 客户列表
export function customerList(data) {
  return request({
    url: '/customer/list',
    method: 'post',
    data: data
  })
}
// 添加客户
export function customerUpsert(data) {
  return request({
    url: '/customer/upsert',
    method: 'post',
    data: data
  })
}

// 删除客户
export function customerDel(id) {
  return request({
    url: '/customer/delete/' + id,
    method: 'delete'
  })
}

export function supplierList(data) {
  return request({
    url: '/supplier/list',
    method: 'post',
    data: data
  })
}
export function supplierInfo(id) {
  return request({
    url: '/supplier/info/' + id,
    method: 'get'
  })
}

export function supplierUpset(data) {
  return request({
    url: '/supplier/upsert',
    method: 'post',
    data: data
  })
}

export function supplierDelete(id) {
  return request({
    url: '/supplier/delete/' + id,
    method: 'delete'
  })
}

export function sellDelete(id) {
  return request({
    url: '/sell_company/delete/' + id,
    method: 'delete'
  })
}

export function upsertSell(data) {
  return request({
    url: '/sell_company/upsert',
    method: 'post',
    data: data
  })
}

export function sellList(data) {
  return request({
    url: '/sell_company/list',
    method: 'post',
    data: data
  })
}

export function sellInfo(id) {
  return request({
    url: '/sell_company/info/' + id,
    method: 'get'
  })
}

export function upsertBuy(data) {
  return request({
    url: '/buy_company/upsert',
    method: 'post',
    data: data
  })
}

export function buyDelete(id) {
  return request({
    url: '/buy_company/delete/' + id,
    method: 'delete'
  })
}

export function buyList(data) {
  return request({
    url: '/buy_company/list',
    method: 'post',
    data: data
  })
}

export function buyInfo(id) {
  return request({
    url: '/buy_company/info/' + id,
    method: 'get'
  })
}

export function upsertReceipt(data) {
  return request({
    url: '/order_receipt/upsert',
    method: 'post',
    data: data
  })
}
export function getReceiptList(data) {
  return request({
    url: '/order_receipt/list',
    method: 'post',
    data: data
  })
}

export function cancelReceipt(data) {
  return request({
    url: '/order_receipt/cancel',
    method: 'post',
    data: data
  })
}

export function receiveReceipt(data) {
  return request({
    url: '/order_receipt/receive',
    method: 'post',
    data: data
  })
}
export function invoiceReceipt(data) {
  return request({
    url: '/order_receipt/invoice',
    method: 'post',
    data: data
  })
}

export function getInvoiceStatus(data) {
  return request({
    url: '/order_receipt/stat',
    method: 'post',
    data: data
  })
}

export function upsertArtworktypes(data) {
  return request({
    url: '/artwork_types/upsert',
    method: 'post',
    data: data
  })
}

export function listArtworktypes() {
  return request({
    url: '/artwork_types/list',
    method: 'get'
  })
}

export function deleteArtworktypes(id) {
  return request({
    url: '/artwork_types/delete/' + id,
    method: 'delete'
  })
}

export function listArtwork(data) {
  return request({
    url: '/artwork/list',
    method: 'post',
    data: data
  })
}

export function upsertArtwork(data) {
  return request({
    url: '/artwork/upsert',
    method: 'post',
    data: data
  })
}

export function listMaterials(data) {
  return request({
    url: '/materials/list',
    method: 'post',
    data: data
  })
}

export function upsertMaterials(data) {
  return request({
    url: '/materials/upsert',
    method: 'post',
    data: data
  })
}

export function infoMaterials(id) {
  return request({
    url: '/materials/info/' + id,
    method: 'get'
  })
}

export function delMaterials(id) {
  return request({
    url: '/materials/delete/' + id,
    method: 'delete'
  })
}

export function delMaterialstypes(id) {
  return request({
    url: '/materials_types/delete/' + id,
    method: 'delete'
  })
}

export function upsertMaterialstypes(data) {
  return request({
    url: '/materials_types/upsert',
    method: 'post',
    data: data
  })
}

export function listMaterialstypes(data) {
  return request({
    url: '/materials_types/list',
    method: 'get',
    data: data
  })
}

// 芯片列表
export function chipList(data) {
  return request({
    url: '/chip/list',
    method: 'post',
    data: data
  })
}

// 芯片添加修改
export function chipUpsert(data) {
  return request({
    url: '/chip/upsert',
    method: 'post',
    data: data
  })
}

// 芯片删除
export function chipDel(id) {
  return request({
    url: '/chip/delete/' + id,
    method: 'delete'
  })
}

// 芯片信息
export function chipInfo(id) {
  return request({
    url: '/chip/info/' + id,
    method: 'get'
  })
}

// 工艺添加修改
export function craftUpsert(data) {
  return request({
    url: '/craft/upsert',
    method: 'post',
    data: data
  })
}

// 工艺列表
export function craftList(data) {
  return request({
    url: '/craft/list',
    method: 'post',
    data: data
  })
}

// 工艺删除
export function craftDel(id) {
  return request({
    url: '/craft/delete/' + id,
    method: 'delete'
  })
}

export function listProduct(data) {
  return request({
    url: '/product/list',
    method: 'post',
    data: data
  })
}

export function upsertProduct(data) {
  return request({
    url: '/product/upsert',
    method: 'post',
    data: data
  })
}

export function infoProduct(id) {
  return request({
    url: '/product/info/' + id,
    method: 'get'
  })
}

export function delProduct(id) {
  return request({
    url: '/product/delete/' + id,
    method: 'delete'
  })
}

export function delProducttypes(id) {
  return request({
    url: '/product_types/delete/' + id,
    method: 'delete'
  })
}

export function upsertProducttypes(data) {
  return request({
    url: '/product_types/upsert',
    method: 'post',
    data: data
  })
}

export function listProducttypes(data) {
  return request({
    url: '/product_types/list',
    method: 'get',
    data: data
  })
}

export function listPacking(data) {
  return request({
    url: '/packing/list',
    method: 'post',
    data: data
  })
}

export function infoPacking(id) {
  return request({
    url: '/packing/info/' + id,
    method: 'get'
  })
}

export function upsertPacking(data) {
  return request({
    url: '/packing/upsert',
    method: 'post',
    data: data
  })
}

export function delPacking(id) {
  return request({
    url: '/packing/delete/' + id,
    method: 'delete'
  })
}

export function upsertPackingTypes(data) {
  return request({
    url: '/packing_types/upsert',
    method: 'post',
    data: data
  })
}

export function listPackingTypes(data) {
  return request({
    url: '/packing_types/list',
    method: 'get',
    data: data
  })
}

export function delPackingTypes(id) {
  return request({
    url: '/packing_types/delete/' + id,
    method: 'delete'
  })
}

// 芯片分类添加修改
export function chipTypesUpsert(data) {
  return request({
    url: '/chip_types/upsert',
    method: 'post',
    data: data
  })
}

// 芯片分类列表
export function chipTypesList(data) {
  return request({
    url: '/chip_types/list',
    method: 'get',
    data: data
  })
}

// 芯片分类删除
export function chipTypesDel(id) {
  return request({
    url: '/chip_types/delete/' + id,
    method: 'delete'
  })
}

export function getProductListName(data) {
  return request({
    url: '/product/auto-complete',
    method: 'post',
    data: data
  })
}

export function getPreviewTemplate(id) {
  return request({
    url: '/artwork_types/preview_template/' + id,
    method: 'get'
  })
}

export function supplierTypeUpsert(data) {
  return request({
    url: '/supplier_types/upsert',
    method: 'post',
    data: data
  })
}

export function supplierTypeList() {
  return request({
    url: '/supplier_types/list',
    method: 'get'
  })
}

export function supplierTypeDel(id) {
  return request({
    url: '/supplier_types/delete/' + id,
    method: 'delete'
  })
}